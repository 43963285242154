import { Injectable } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';

@Injectable({
	providedIn: 'root'
})
export class NgReactiveFormValidatorService {

	constructor() { }

	/**
	 * Generate validation errors for forms
	 * @param formObj FormGroup
	 * @param fieldMessage any
	 */
	validationError(formObj: FormGroup, fieldMessage: any = {}) {
		const errorArr = {};
		const deafultErrorMsg = {
			required: 'This field is required',
			email: 'Invalid email id',
			min: 'Please enter minimum value',
			max: 'Value exceeds maximum limit',
			minlength: 'Please enter minimum characters',
			maxlength: 'Value exceeds maximum characters',
			defaultError: 'This field is invalid'
		};
		Object.keys(formObj.controls).forEach((el) => {
			errorArr[el] = [];
			if (formObj.controls[el] instanceof FormControl) {
				if (formObj.controls[el].errors) {
					const keyArr = Object.keys(formObj.controls[el].errors);
					keyArr.forEach((keyEl) => {
						let msg = '';
						switch (keyEl) {
							case 'required':
								if (el in fieldMessage) {
									if ('required' in fieldMessage[el]) {
										msg = fieldMessage[el].required;
									} else {
										msg = deafultErrorMsg.required;
									}
								} else {
									msg = deafultErrorMsg.required;
								}
								const reqError = { type: 'required', message: msg };
								errorArr[el].push(reqError);
								break;

							case 'email':
								if (el in fieldMessage) {
									if ('email' in fieldMessage[el]) {
										msg = fieldMessage[el].email;
									} else {
										msg = deafultErrorMsg.email;
									}
								} else {
									msg = deafultErrorMsg.email;
								}
								const emailError = { type: 'email', message: msg };
								errorArr[el].push(emailError);
								break;

							case 'min':
								if (el in fieldMessage) {
									if ('min' in fieldMessage[el]) {
										msg = fieldMessage[el].min;
									} else {
										msg = deafultErrorMsg.min + ' ' + formObj.controls[el].errors.min.min;
									}
								} else {
									msg = deafultErrorMsg.min + ' ' + formObj.controls[el].errors.min.min;
								}
								const minError = { type: 'min', message: msg };
								errorArr[el].push(minError);
								break;

							case 'max':
								if (el in fieldMessage) {
									if ('max' in fieldMessage[el]) {
										msg = fieldMessage[el].max;
									} else {
										msg = deafultErrorMsg.max + ' ' + formObj.controls[el].errors.max.max;
									}
								} else {
									msg = deafultErrorMsg.max + ' ' + formObj.controls[el].errors.max.max;
								}
								const maxError = { type: 'max', message: msg };
								errorArr[el].push(maxError);
								break;

							case 'minlength':
								if (el in fieldMessage) {
									if ('minlength' in fieldMessage[el]) {
										msg = fieldMessage[el].minlength;
									} else {
										msg = deafultErrorMsg.minlength + ' ' + formObj.controls[el].errors.minlength.requiredLength;
									}
								} else {
									msg = deafultErrorMsg.minlength + ' ' + formObj.controls[el].errors.minlength.requiredLength;
								}
								const minlengthError = { type: 'minlength', message: msg };
								errorArr[el].push(minlengthError);
								break;

							case 'maxlength':
								if (el in fieldMessage) {
									if ('maxlength' in fieldMessage[el]) {
										msg = fieldMessage[el].maxlength;
									} else {
										msg = deafultErrorMsg.maxlength + ' ' + formObj.controls[el].errors.maxlength.requiredLength;
									}
								} else {
									msg = deafultErrorMsg.maxlength + ' ' + formObj.controls[el].errors.maxlength.requiredLength;
								}
								const maxlengthError = { type: 'maxlength', message: msg };
								errorArr[el].push(maxlengthError);
								break;

							default:
								if (el in fieldMessage) {
									if (keyEl in fieldMessage[el]) {
										msg = fieldMessage[el][keyEl];
									} else {
										msg = deafultErrorMsg.defaultError;
									}
								} else {
									msg = deafultErrorMsg.defaultError;
								}
								const customError = { type: keyEl, message: msg };
								errorArr[el].push(customError);
								break;
						}
					});
					// if (formObj.controls[el].errors.required) {
					// 	let msg = '';
					// 	if (el in fieldMessage) {
					// 		if ('required' in fieldMessage[el]) {
					// 			msg = fieldMessage[el].required;
					// 		} else {
					// 			msg = deafultErrorMsg.required;
					// 		}
					// 	} else {
					// 		msg = deafultErrorMsg.required;
					// 	}
					// 	const reqError = { type: 'required', message: msg };
					// 	errorArr[el].push(reqError);
					// }
					// if (formObj.controls[el].errors.email) {
					// 	let msg = '';
					// 	if (el in fieldMessage) {
					// 		if ('email' in fieldMessage[el]) {
					// 			msg = fieldMessage[el].email;
					// 		} else {
					// 			msg = deafultErrorMsg.email;
					// 		}
					// 	} else {
					// 		msg = deafultErrorMsg.email;
					// 	}
					// 	const emailError = { type: 'email', message: msg };
					// 	errorArr[el].push(emailError);
					// }
					// if (formObj.controls[el].errors.min) {
					// 	let msg = '';
					// 	if (el in fieldMessage) {
					// 		if ('min' in fieldMessage[el]) {
					// 			msg = fieldMessage[el].min;
					// 		} else {
					// 			msg = deafultErrorMsg.min + ' ' + formObj.controls[el].errors.min.min;
					// 		}
					// 	} else {
					// 		msg = deafultErrorMsg.min + ' ' + formObj.controls[el].errors.min.min;
					// 	}
					// 	const minError = { type: 'min', message: msg };
					// 	errorArr[el].push(minError);
					// }
					// if (formObj.controls[el].errors.max) {
					// 	let msg = '';
					// 	if (el in fieldMessage) {
					// 		if ('max' in fieldMessage[el]) {
					// 			msg = fieldMessage[el].max;
					// 		} else {
					// 			msg = deafultErrorMsg.max + ' ' + formObj.controls[el].errors.max.max;
					// 		}
					// 	} else {
					// 		msg = deafultErrorMsg.max + ' ' + formObj.controls[el].errors.max.max;
					// 	}
					// 	const maxError = { type: 'max', message: msg };
					// 	errorArr[el].push(maxError);
					// }
					// if (formObj.controls[el].errors.minlength) {
					// 	let msg = '';
					// 	if (el in fieldMessage) {
					// 		if ('minlength' in fieldMessage[el]) {
					// 			msg = fieldMessage[el].minlength;
					// 		} else {
					// 			msg = deafultErrorMsg.minlength + ' ' + formObj.controls[el].errors.minlength.requiredLength;
					// 		}
					// 	} else {
					// 		msg = deafultErrorMsg.minlength + ' ' + formObj.controls[el].errors.minlength.requiredLength;
					// 	}
					// 	const minlengthError = { type: 'minlength', message: msg };
					// 	errorArr[el].push(minlengthError);
					// }
					// if (formObj.controls[el].errors.maxlength) {
					// 	let msg = '';
					// 	if (el in fieldMessage) {
					// 		if ('maxlength' in fieldMessage[el]) {
					// 			msg = fieldMessage[el].maxlength;
					// 		} else {
					// 			msg = deafultErrorMsg.maxlength + ' ' + formObj.controls[el].errors.maxlength.requiredLength;
					// 		}
					// 	} else {
					// 		msg = deafultErrorMsg.maxlength + ' ' + formObj.controls[el].errors.maxlength.requiredLength;
					// 	}
					// 	const maxlengthError = { type: 'maxlength', message: msg };
					// 	errorArr[el].push(maxlengthError);
					// }
				}
			} else if (formObj.controls[el] instanceof FormGroup) {
				let errorMsg = {};
				if (el in fieldMessage) {
					errorMsg = fieldMessage[el];
				}
				errorArr[el] = this.validationError(formObj.controls[el] as FormGroup, errorMsg);
			} else if (formObj.controls[el] instanceof FormArray) {
				errorArr[el] = [];
				const formArr = formObj.get(el) as FormArray;
				formArr.controls.forEach((arrayElm) => {
					const errObj = this.validationError(arrayElm as FormGroup, {});
					errorArr[el].push(errObj);
				});
			}
		});

		return JSON.parse(JSON.stringify(errorArr));
	}

	getErrorList(formObj: FormGroup, fieldMessage: any = {}) {
		const formError = this.validationError(formObj, fieldMessage);
		let errorStr = '';
		if (Object.keys(formError).length > 0) {
			Object.keys(formError).forEach((key) => {
				if (formError[key].length > 0) {
					let keyMap = key;
					keyMap = keyMap.split('_').join(' ');
					keyMap = keyMap.replace(/^\w/, c => c.toUpperCase());
					errorStr += '<li>';
					const errorArr = formError[key].map((el) => {
						return el.message;
					});
					errorStr += '<b>' + keyMap + '</b>' + ' : ' + errorArr.join(', ');
					errorStr += '</li>';
				}
			});
			errorStr = '<ul>' + errorStr + '</ul>';
		}
		return errorStr;
	}
}
