import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { NgReactiveFormValidatorService } from './ng-reactive-form-validator.service';

@NgModule({
	imports: [
		FormsModule
	],
	declarations: [],
	exports: [],
	providers: [
		NgReactiveFormValidatorService
	]
})
export class NgReactiveFormValidatorModule { }
